/* eslint-disable no-var, no-console */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

/** High: "trust_diff", low: "dealer_diff", open: "foreigner_diff", close: "上面加總" volume: "dealer_hedge_diff" */
export const dealer_mxf = createIndicator({
  id: 'dealermxf',
  displayName: '自營商買賣超',
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker + '#big3_net_stock'
      this._context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      this._context.select_sym(1)
      const o = this.PineJS.Std.low(this._context)
      const o_pos = o > 0 ? o : NaN
      const o_neg = o < 0 ? o : NaN
      return [o_pos, o_neg]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: false,
    isCustomIndicator: true,

    plots: [
      { id: 'plot_0', type: 'line' },
      { id: 'plot_1', type: 'line' },
    ],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 2,
          plottype: 5,
          trackPrice: false,
          transparency: 40,
          color: '#ff0000',
        },
        plot_1: {
          linestyle: 0,
          visible: true,
          linewidth: 2,
          plottype: 5,
          trackPrice: false,
          transparency: 40,
          color: '#007f00',
        },
      },
      precision: 0,
      inputs: {},
    },
    styles: {
      plot_0: {
        title: '買超',
        histogramBase: 0,
      },
      plot_1: {
        title: '賣超',
        histogramBase: 0,
      },
    },
    inputs: [],
  },
})
